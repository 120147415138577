import React, {useContext} from "react";
// import "../../styles/layout.scss";
import Header from "../header/Header";
import Search from "../search/Search";
import {SearchContext} from "../../context/SearchContext";

interface layout_props {
    children: React.ReactElement | React.ReactElement[] | React.ReactNode;
}

/**
 *
 */
const Layout = (props: layout_props) => {

    return (
        <div className="layout">
            <Header />
            <Search />
            {props.children}
            {/*<footer></footer>*/}
        </div>
    );
};

export default Layout