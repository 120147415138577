import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-L57HQH9DH2"; // Google Analytics 측정 ID

ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const AppWithAnalytics = () => {
    const location = useLocation();

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return <App />;
};

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppWithAnalytics />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
