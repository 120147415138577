import React, {useContext, useState} from 'react';
import './Header.css';
import {useNavigate} from "react-router-dom";
import {SearchContext} from "../../context/SearchContext";

const Header: React.FC = () => {
    const navigate = useNavigate();
    const context = useContext(SearchContext);

    if (!context) {
        throw new Error("SearchContext must be used within a SearchProvider");
    }
    const { setKeyword } = context;


    const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setKeyword(""); // 검색어를 부모 컴포넌트에 전달
        navigate("/")
    }
    return (
        <header className="header"  onClick={onClick}>
            <img className="logo" src={"wegif-logo.png"} alt="Wegif Logo" />
            <h1 onClick={() => navigate("/")}>PETPHY</h1>
        </header>
    );
}

export default Header;
