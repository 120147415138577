import React, {useContext, useEffect, useState} from 'react';
import './Search.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {SearchContext} from "../../context/SearchContext";
import {keyboard} from "@testing-library/user-event/dist/keyboard";
import {useNavigate} from "react-router-dom";


const Search: React.FC = () => {
    const context = useContext(SearchContext);
    const navigate = useNavigate();
    if (!context) {
        throw new Error("SearchContext must be used within a SearchProvider");
    }
    const {keyword, setKeyword} = context;
    const [inputValue, setInputValue] = useState<string>("");


    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
    }
    const handleSearch = () => {

        if (inputValue.trim() !== "") {
            setKeyword(inputValue); // 검색어를 부모 컴포넌트에 전달
        }

        navigate(`/`);
    };

    const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setKeyword("")
        event.preventDefault();
        handleSearch();
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 기본 Enter 동작 방지
            handleSearch(); // Enter 키를 누르면 검색 실행
        }
    };

    useEffect(() => {
        if (keyword === "") {
            setInputValue("")
        }
    }, [keyword])


    return (
        <div className={"search-container"}>
            <input className={"search-input"} type="text" value={inputValue} onChange={onChange} onKeyDown={onKeyDown}
                   placeholder={"search..."}/>
            <div className={"search-button"} onClick={onClick}>
                <FontAwesomeIcon icon={faSearch} className="search-icon"/>
            </div>
        </div>
    );
}
// @ts-ignore
export default Search;
