import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface SearchContextProps {
    keyword: string;
    setKeyword: Dispatch<SetStateAction<string>>;
}

export const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [keyword, setKeyword] = useState<string>("");

    return (
        <SearchContext.Provider value={{ keyword, setKeyword }}>
            {children}
        </SearchContext.Provider>
    );
};
