import React, {useCallback, useEffect, useState, useContext, useRef} from 'react';
import Masonry from 'react-masonry-css';
import './GifGrid.css';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import Spinner from '../spinner/Spinner';
import { SearchContext } from "../../context/SearchContext";
import axiosInstance from "../../api/http";
import KakaoAdFit from "../common/ads/KakaoAdfit";

interface Gif {
    id: number;
    url: string;
}

const GifGrid: React.FC = () => {
    const context = useContext(SearchContext);
    const scriptElement = useRef(null);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);


    if (!context) {
        throw new Error("SearchContext must be used within a SearchProvider");
    }

    const { keyword } = context;
    const [ref, inView] = useInView({
        threshold: 0,
    });
    const [gifs, setGifs] = useState<Gif[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [lastId, setLastId] = useState<number | null>(null);
    const navigate = useNavigate();

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const fetchGifs = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            const params = lastId ? { lastId: lastId, keyword: keyword } : { keyword: keyword };
            const response = await axiosInstance.get(`/post/get`, { params });
            const gifData = response.data.content.map((gif: any) => ({
                id: gif.id,
                url: gif.images[0].imageUrl,
            }));
            setGifs((prevGifs) => [...prevGifs, ...gifData]);
            setLastId(response.data.content[response.data.content.length - 1]?.id || null);
            setHasMore(!response.data.last);
        } catch (error) {
            console.error('Error fetching GIFs:', error);
            setError(true);
        } finally {
            await sleep(300);
            setLoading(false);
        }
    }, [keyword, lastId, hasMore, loading]);

    useEffect(() => {
        setGifs([]);
        setLastId(null);
        setHasMore(true);
        setError(false);
    }, [keyword]);

    useEffect(() => {
        if (inView && hasMore && !loading) {
            fetchGifs();
        }
    }, [inView, fetchGifs]);

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        375: 2, // 모바일 화면에서는 3열로 표시
    };

    const moveDetailPage = (id: number) => {
        navigate(`/post/get/${id}`);
    };
    // script 태그를 동적으로 추가
    useEffect(() => {
        const script = document.createElement("script");
        script.setAttribute(
            "src",
            "https://t1.daumcdn.net/kas/static/ba.min.js"
        );
        script.setAttribute(
            "charset",
            "utf-8"
        );

        script.setAttribute("async", "true");
        // scriptElement.current가 null이 아닌지 확인
        if (scriptElement.current) {
            (scriptElement.current as HTMLElement).appendChild(script);
        }
    }, []);
    useEffect(() => {
        // 화면 크기 변화 감지
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className="grid_layout">
            <div className="ad-section">
                {windowWidth < 768 ? (
                    <KakaoAdFit
                        unit="DAN-eh6LXKrRtWrS81tL"
                        width="320"
                        height="100"
                    />
                    // <div ref={scriptElement}>
                    //     <ins
                    //         className="kakao_ad_area"
                    //         style={{ display: "none" }}
                    //         data-ad-unit="DAN-eh6LXKrRtWrS81tL"
                    //         data-ad-width="320"
                    //         data-ad-height="100"
                    //     />
                    // </div>
                ) : (
                    <KakaoAdFit
                        unit="DAN-1pbk8OgpYPkRUcYp"
                        width="728"
                        height="90"
                    />
                    // <div ref={scriptElement}>
                    //     <ins
                    //         className="kakao_ad_area"
                    //         style={{ display: "none" }}
                    //         data-ad-unit="DAN-1pbk8OgpYPkRUcYp"
                    //         data-ad-width="728"
                    //         data-ad-height="90"
                    //     />
                    // </div>
                )}
            </div>
            <div className="masonry-container">
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {gifs.map((gif, index) => (
                        <React.Fragment key={gif.id}>
                            <div className="gif-item" onClick={() => moveDetailPage(gif.id)}>
                                <img src={gif.url} alt="Gif" />
                            </div>
                        </React.Fragment>
                    ))}
                </Masonry>
            </div>
            <div className="loading-container">
                {loading && <Spinner />}
                {!error && <div ref={ref} style={{ height: 20 }} />}
            </div>
        </div>
    );
};

export default GifGrid;
