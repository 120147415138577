// @flow
import * as React from 'react';
import {grid} from 'ldrs'


const Spinner:React.FC = () => {
    grid.register()

    return <>
        <div className="ldr-grid">
            <l-grid
                size="60"
                speed="1.5"
                color="white"
            ></l-grid>
        </div>
    </>
};

export default Spinner;