import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ImageSlider.css'

interface Image {
    id: number;
    imageUrl: string;
}

interface ImageSliderProps {
    imgLists: Image[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ imgLists }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const renderMedia = (img: Image) => {
        const isVideo = img.imageUrl.endsWith('.mp4');
        if (isVideo) {
            return (
                <video controls style={{ maxWidth: '100%', maxHeight: '100%' }} >
                    <source src={img.imageUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return <img src={img.imageUrl} alt={`Detail ${img.id}`} style={{ maxWidth: '100%', maxHeight: '100%' }}/>;
        }
    };

    return (
        <div className={"slider-section"}>
        {/*<>*/}
            <Slider {...settings}>
                {imgLists.map((img) => (
                    <div key={img.id}>
                        {renderMedia(img)}
                    </div>
                ))}
            </Slider>
        </div>
        // </>
    );
};

export default ImageSlider;
