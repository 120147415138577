import {useEffect, useRef} from "react";
declare global {
    interface Window {
        adfit: any; // adfit 객체가 어떻게 정의되는지에 따라 적절한 타입으로 변경 가능
    }
}
function KakaoAdFit({unit, width, height}: any) {
    const scriptElement = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://t1.daumcdn.net/kas/static/ba.min.js');
        script.setAttribute('charset', 'utf-8');
        if (scriptElement.current) {
            (scriptElement.current as HTMLElement).appendChild(script);
        }

        return () => {
            const globalAdfit = window.adfit;
            if (globalAdfit) globalAdfit.destroy(unit);
        };
    }, []);

    return <div ref={scriptElement}>
        <ins className="kakao_ad_area" style={{display: "none"}}
             data-ad-unit={unit}
             data-ad-width={width}
             data-ad-height={height}></ins>
    </div>
}

export default KakaoAdFit;