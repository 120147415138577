import React from 'react';
import './App.css';
import Layout from "../src/components/common/Layout";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import {Detail} from "./pages/detail/Detail";
import {SearchProvider} from "./context/SearchContext";

const App: React.FC = () => {
  return (
      <SearchProvider>
          <div className="container">
              <Layout>
                  <Routes>
                      <Route path="/" element={<Home />}></Route>
                      <Route path="/post/get/:id" element={<Detail />}></Route>
                  </Routes>
              </Layout>
          </div>
      </SearchProvider>
  );
}

export default App;
