import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import Spinner from "../../components/spinner/Spinner";
import './Detail.css'
import ImageSlider from "../../components/imageSlider/ImageSlider";
import axiosInstance from "../../api/http";
import KakaoAdFit from "../../components/common/ads/KakaoAdfit";

interface ImageList {
    id: number;
    imageUrl: string;
}

interface Response {
    id: number;
    title: string;
    contents: string;
    imgLists: ImageList[];
    downloadUrl: string;
    tags: string[];
}

export const Detail = () => {
    const {id} = useParams();
    const [res, setRes] = React.useState<Response | null>(null);
    const scriptElement = useRef(null);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const getDetailData = async (id: string | undefined) => {
        if (id) {
            try {
                const response = await axiosInstance.get(`/post/get/${id}`);
                setRes(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        getDetailData(id);

    }, [id]);
    useEffect(() => {
        // 화면 크기 변화 감지
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // script 태그를 동적으로 추가
    useEffect(() => {
        const script = document.createElement("script");
        script.setAttribute(
            "src",
            "https://t1.daumcdn.net/kas/static/ba.min.js"
        );
        script.setAttribute(
            "charset",
            "utf-8"
        );

        script.setAttribute("async", "true");
        // scriptElement.current가 null이 아닌지 확인
        if (scriptElement.current) {
            (scriptElement.current as HTMLElement).appendChild(script);
        }
    }, []);

    return (
        <>
            {res ? (
                <>
                    <div className={"data-area"}>
                        <div className={"img-section"}>
                            <ImageSlider imgLists={res.imgLists}/>
                        </div>
                        <div className="text_section">
                            <p className="title">{res.title}</p>
                            <p className="contents">{res.contents}</p>
                            <div className="tags">
                                <p className="tags-title">Tags:</p>
                                {res.tags.map((tag, index) => (
                                    <span key={index} className="tag">{tag}</span>
                                ))}
                            </div>
                            <div className="download-buttons">
                                <div className="dropdown">
                                    <div className="dropdown-button">
                                        Download
                                        <span style={{marginLeft: '10px'}}>▼</span>
                                    </div>
                                    <div className="dropdown-content">
                                        <a href={res.downloadUrl} download>All Download</a>
                                        <a href={res.imgLists[0].imageUrl} download="test.gif">GIF Download</a>
                                        <a href={res.imgLists[1].imageUrl} download="mp4t.mp4">MP4 Download</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="ad-sections">
                        {windowWidth < 768 ? (
                            <KakaoAdFit
                                unit="DAN-wxRjUysi9F1Aoye3"
                                width="320"
                                height="100"
                            />
                            // <div ref={scriptElement}>
                            //     <ins
                            //         className="kakao_ad_area"
                            //         style={{display: "none"}}
                            //         data-ad-unit="DAN-wxRjUysi9F1Aoye3"
                            //         data-ad-width="320"
                            //         data-ad-height="100"
                            //     />
                            // </div>
                        ) : (
                            <KakaoAdFit
                                unit="DAN-SPZfpREzpZbZVxw2"
                                width="728"
                                height="90"
                            />
                            // <div ref={scriptElement}>
                            //     <ins
                            //         className="kakao_ad_area"
                            //         style={{display: "none"}}
                            //         data-ad-unit="DAN-SPZfpREzpZbZVxw2"
                            //         data-ad-width="728"
                            //         data-ad-height="90"
                            //     />
                            // </div>
                        )}
                    </div>
                </>
            ) : (
                <Spinner/>
            )}
        </>
    );
};
